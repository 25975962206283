@use '../../../../styles/size.scss' as v;
@import 'styles/layers.scss';

@layer component {
  .wrap {
    height: v.$height-table-filters;
    margin-bottom: v.$mb-table-filters;
    width: 1083px;
  }
  
  .mr {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  
  .dropdown-toggle {
    background-color: transparent;
  }
  
  .dropdown-menu {
    padding: 0;
    background-color: var(--black14);
    &:hover {
      background: var(--black14);
    }
    right: 0;
  }
  
  @media screen and (max-width: 1120px) {
    .switcher {
      display: none;
    }
    .mr {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }  
}
