@import 'styles/layers.scss';

@layer component {
  .dropdown-toggle {
    background-color: var(--black3);
    height: 40px;
    padding: 0 12px;
    color: var(--white);
  }
  
  .dropdown-menu {
    padding: 0;
    border-radius: 6px;
    width: 115px;
    min-width: 115px;
  }
  
  .dropdown-menu-item {
    height: 44px;
    width: 115px;
  }
}